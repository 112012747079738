import {Subject} from 'rxjs';

let swipeEvent: Subject<boolean> = null;
let start: { x: number, y: number, date: Date };

export default (): Subject<boolean> => {
	if (swipeEvent) {
		return swipeEvent;
	}

	swipeEvent = new Subject<boolean>();

	window.addEventListener('touchstart', touchStart);
	window.addEventListener('touchmove', touchMove);

	return swipeEvent;
};

const touchStart = (event: TouchEvent): void => {
	start = {
		x: event.touches[0].clientX,
		y: event.touches[0].clientY,
		date: new Date(),
	};
};

const touchMove = (event: TouchEvent): void => {
	const dataY = different(start.y, event.touches[0].clientY);
	const dataX = different(start.x, event.touches[0].clientX);

	const isTooMuchTime = differentTime(start.date, new Date()) > 200;

	if (dataY.different > 20 || dataX.different < 50) {
		return;
	}
	if (isTooMuchTime) {
		return;
	}

	swipeEvent.next(dataX.isMore);
};

const different = (startPoint: number, point: number): { different: number, isMore: boolean } => {
	const different = getPercent(startPoint) - getPercent(point);

	return {
		different: Math.abs(different),
		isMore: different < 0,
	};
};

const differentTime = (date1: Date, date2: Date) =>
	Math.abs(date2.getTime() - date1.getTime());

const getPercent = (x: number) => Math.floor(x / window.innerWidth * 100);


import {
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventBusService } from '@shared/utils/event-bus';
import { scaleAnimation } from '../../animations/common.animations';

@UntilDestroy()
@Component({
	selector: 'app-scroll-to-top',
	templateUrl: './scroll-to-top.component.html',
	styleUrls: ['./scroll-to-top.component.scss'],
	animations: [scaleAnimation],
})
export class ScrollToTopComponent implements OnChanges, OnInit {
	@Input() cdkVirtualScrollViewport?: CdkVirtualScrollViewport;
	@Input() id = 'scroll-to-index';

	public isVisibleButton: boolean;

	constructor(
		private _eventBus: EventBusService,
		private _changeDetector: ChangeDetectorRef
	) {
		this.isVisibleButton = false;
	}

	public ngOnInit(): void {
		this._eventBus
			.on(this.id)
			.pipe(untilDestroyed(this))
			.subscribe((index: number) => this.clickToIndex(index));
	}

	public clickToIndex(index: number): void {
		if (index < 0) {
			index = 0;
		}

		if (this.cdkVirtualScrollViewport) {
			this.cdkVirtualScrollViewport.scrollToIndex(index, 'smooth');
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.cdkVirtualScrollViewport?.currentValue) {
			this.cdkVirtualScrollViewport?.scrolledIndexChange
				.pipe(untilDestroyed(this))
				.subscribe(index => {
					if (this.isVisibleButton === Boolean(index)) {
						return;
					}

					this.isVisibleButton = Boolean(index);

					this._changeDetector.detectChanges();
				});
		}
	}
}

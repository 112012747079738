import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatTooltipModule} from '@angular/material/tooltip';

import {ScrollToTopModule} from '@shared/ui-components/scroll-to-top/scroll-to-top.module';
import {StopPropagationModule} from '@shared/directives/stop-propagation/stop-propagation.module';
import {
	HeaderItemDirective,
	HeaderTableDirective,
	TablesDirective,
	ValueTableDirective,
	ViewItemDirective
} from '@shared/ui-components/table/directives/tables.directive';
import {TableHeaderComponent} from '@shared/ui-components/table/components/table-header/table-header.component';
import {TableItemComponent} from '@shared/ui-components/table/components/table-item/table-item.component';
import {TableComponent} from '@shared/ui-components/table/table.component';
import {NotFoundDataModule} from '@shared/ui-components/not-found/not-found-data.module';
import {EmptyDataComponent} from '@shared/ui-components/empty-data/empty-data.component';
import {CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import {ButtonComponent} from '@shared/ui-components/button/button.component';


@NgModule({
	declarations: [
		TableComponent,
		TableItemComponent,
		TableHeaderComponent,
		TablesDirective,
		ViewItemDirective,
		HeaderTableDirective,
		ValueTableDirective,
		HeaderItemDirective,
	],
	exports: [
		TableComponent,
		TableItemComponent,
		TableHeaderComponent,
		TablesDirective,
		ViewItemDirective,
		HeaderTableDirective,
		ValueTableDirective,
		HeaderItemDirective,
	],
	imports: [
		CommonModule,
		MatIconModule,
		StopPropagationModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		ReactiveFormsModule,
		ScrollingModule,
		MatTooltipModule,
		ScrollToTopModule,
		NotFoundDataModule,
		EmptyDataComponent,
		CdkDropList,
		CdkDrag,
		ButtonComponent,
	],
})
export class TableModule {
}

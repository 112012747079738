type TTree = {
	id: number;
	parent?: TTree,
	children?: TTree[],
};

export default {
	cloneTree,
	sort,
	every,
	getRoot,
	eachDown,
	eachFromRoot,
	eachFromChildren,
	eachParentToRoot,
	getPropertiesSelectedNodes,
	eachDownForPair: eachDownForPairById,
	isEqualById,
};

function cloneTree<T extends TTree, K extends TTree>(
	tree: T[] | T,
	callback?: (oldNode: T) => K,
): K[] | K {
	const clone = (tree: T[] | T, parent) => {
		if (!tree) {
			return [];
		}

		if (Array.isArray(tree)) {
			return tree.map((t) => clone(t, parent));
		}

		const newNode = callback(tree);

		if (parent) {
			newNode.parent = parent;
		}

		if (tree.children?.length) {
			// @ts-ignore
			newNode.children = clone(tree.children, newNode);
		}

		return newNode;
	};

	return clone(tree, null);
}

function sort<T extends TTree>(
	tree: T[] | T,
	condition: (a: T, b: T) => 1 | -1 | 0
) {
	if (!tree) {
		return;
	}

	if (Array.isArray(tree)) {
		tree.sort(condition);
	} else {
		sort(tree.children, condition);
	}
}

function every<T extends TTree>(
	tree: T[] | T,
	condition: (a: T) => boolean
): boolean {
	if (!tree) {
		return false;
	}

	if (Array.isArray(tree)) {
		return tree.reduce((p, c) => condition(c) && p, true);
	}

	if (tree.children?.length) {
		return condition(tree) &&
			tree.children.reduce(
				(p, c) => condition(c as T) && p,
				true
			);
	}

	return condition(tree);
}

function getRoot<T extends TTree>(tree: T): T {
	if (!tree) {
		return null;
	}

	if (tree.parent) {
		return getRoot(tree.parent) as T;
	}

	return tree;
}

function eachDown<T extends TTree>(
	tree: T[] | T,
	callback: (i: T) => void
) {
	if (!tree) {
		return;
	}

	if (Array.isArray(tree)) {
		tree.forEach((t) => eachDown(t, callback));

		return;
	}

	callback(tree);

	if (tree?.children?.length) {
		eachDown(tree.children, callback);
	}
}

function eachDownForPairById<T extends TTree, T2 extends TTree>(
	tree: T[] | T,
	tree2: T2[] | T2,
	callback: (t1: T, t2: T2) => void
): void {
	if (!tree || !tree2) {
		return;
	}

	if (Array.isArray(tree) && Array.isArray(tree2)) {
		tree.forEach(t1 => {
			const t2 = tree2.find(({id}) => id === t1.id);

			if (!t2) {
				return;
			}

			eachDownForPairById(t1, t2, callback);
		});

		return;
	} else if (Array.isArray(tree) || Array.isArray(tree2)) {
		return;
	}

	callback(tree, (tree2 as T2));

	if (tree?.children?.length) {
		eachDownForPairById(tree.children, (tree2 as T2).children, callback);
	}
}

function isEqualById<T extends TTree>(
	tree: T[] | T,
	tree2: T[] | T,
): boolean {
	if (tree === undefined) {
		return tree === tree2;
	}

	const isTreeArray1 = Array.isArray(tree);
	const isTreeArray2 = Array.isArray(tree2);

	if (isTreeArray1 !== isTreeArray2) {
		return false;
	}

	const isArrays = isTreeArray1 && isTreeArray2;

	if (!isArrays) {
		return (tree as T)?.id === (tree2 as T)?.id &&
			isEqualById((tree as T)?.children, (tree2 as T)?.children);
	}

	if (tree.length !== tree2.length) {
		return false;
	}

	for (const node1 of (tree as T[])) {
		const node2 = (tree2 as T[]).find(({id}) => id === node1.id);

		if (!node2) {
			return false;
		}

		if (!isEqualById(node1, node2)) {
			return false;
		}
	}

	return true;
}

function eachUp<T extends TTree>(
	tree: T[] | T,
	callback: (i: T) => void
) {
	if (!tree) {
		return;
	}

	if (Array.isArray(tree)) {
		tree.forEach((t) => eachUp(t, callback));

		return;
	}

	if (tree.children?.length) {
		eachUp(tree.children, callback);
	}

	callback(tree);
}

function eachFromRoot<T extends TTree>(
	tree: T | T[],
	callback: (i: T) => void
) {
	const parents = Array.isArray(tree)
		? tree.map(getRoot)
		: [getRoot(tree)];

	eachDown(parents, callback);
}

function eachFromChildren<T extends TTree>(
	tree: T | T[],
	callback: (i: T) => void,
) {
	const parents = Array.isArray(tree)
		? tree.map(getRoot)
		: [getRoot(tree)];

	eachUp(parents, callback);
}

function eachParentToRoot<T extends TTree>(
	tree: T | T[],
	callback: (i: T) => void
) {
	if (!tree) {
		return;
	}

	if (Array.isArray(tree)) {
		tree.forEach((t) => eachFromChildren(t, callback));

		return;
	}

	callback(tree);

	eachParentToRoot(tree.parent, callback);
}

function getPropertiesSelectedNodes(nodes, idName: string): any[] {
	const checkedIds = [];

	if (nodes) {
		nodes.forEach(node => {
			if (node.isChecked) {
				// Заглушка для нод , которые содержат счетчики
				if (node.counter) {
					checkedIds.push(node.counter[idName]);
				} else {
					checkedIds.push(node[idName]);
				}
			}

			const checkedChildren = getPropertiesSelectedNodes(node.children, idName);

			if (checkedChildren && checkedChildren.length > 0) {
				checkedChildren.forEach(checkedChild => {
					checkedIds.push(checkedChild);
				});
			}
		});
	}

	return checkedIds;
}



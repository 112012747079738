import {cloneDeep} from 'lodash';

const deepProxyCopy = <T extends Object>(value: T): T => {
	const objectWithChanges = {};

	return new Proxy(value, {
		get: (target, prop) => {
			if (objectWithChanges[prop] !== undefined) {
				return objectWithChanges[prop];
			} else if (typeof target[prop] !== 'object' || !target[prop]) {
				return target[prop];
			}

			objectWithChanges[prop] = target[prop].__proto__ === Object.prototype
				? deepProxyCopy(target[prop])
				: cloneDeep(target[prop]);

			return objectWithChanges[prop];
		},
		set: (_, prop, value) => (objectWithChanges[prop] = value, true)
	});
};

export default deepProxyCopy;

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

type TEvent = { name: Events | string | number, value?: any };

@Injectable({providedIn: 'root'})
export class EventBusService {
	private subjects: Map<string | number | Events, BehaviorSubject<any>>;

	constructor() {
		this.subjects = new Map<string | Events, BehaviorSubject<any>>();
	}

	public on(name: Events | string | number): BehaviorSubject<any> {
		return this.getSubject(name);
	}

	public get(name: Events | string | number): any {
		return this.getSubject(name).getValue();
	}

	public emit(event: TEvent): void {
		this.getSubject(event.name).next(event.value);
	}

	private getSubject(key: number | string | Events): BehaviorSubject<any> {
		if (this.subjects.get(key)) {
			return this.subjects.get(key);
		}

		const newValue = new BehaviorSubject<any>(null);

		this.subjects.set(key, newValue);

		return newValue;
	}
}

export enum Events {
	SELECT_UNITS,
	UPDATE_IDLE_TIME,
	UPDATE_PERSONAL_TASKS,
	STATISTIC_WIDGET_LOADING,
	TASKS_WIDGET_LOADING,
	SELECT_USER,
	SELECTED_TYPE_SHIFTS,
	CLICK_AFTER_INTERVAL,
	UPDATE_UNIT_DATES,
}

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {HeaderItemDirective, TablesDirective, ViewItemDirective} from '@shared/ui-components/table/directives/tables.directive';
import {TableHeaderComponent} from '@shared/ui-components/table/components/table-header/table-header.component';
import {nextTick} from '../../utils';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ITableHeaderEvent} from '@shared/ui-components/table/models/table.types';

const DEFAULT_SIZE_COLUMN = '\nminmax(100px, 1.67fr)\n';

const TABLE_SIZE_COLUMN = {
	little: '\nminmax(75px, 1fr)\n',
	medium: '\nminmax(120px, 1.75fr)\n',
	large: '\nminmax(120px, 2.5fr)\n',
};

@UntilDestroy()
@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges {
	@ContentChildren(TableHeaderComponent)
	tableHeaders!: QueryList<TableHeaderComponent>;

	@ViewChild('vitualScroll') vitualScroll: CdkVirtualScrollViewport;
	@ViewChild('tableRef') tableRef: ElementRef;

	@Input() data: Array<any> = [];
	@Input() itemSize = '32';
	@Input() useVirtualScroll = true;
	@Input() isServerSide: boolean = false;
	@Input() useDragAndDrop = false;
	@Output() dropListDropped: EventEmitter<CdkDragDrop<any[]>>
		= new EventEmitter<CdkDragDrop<any[]>>();

	private _lineValues!: QueryList<TablesDirective>;
	private _viewItems!: QueryList<ViewItemDirective>;

	private _originData: Array<any>;
	private _headers: TableHeaderComponent[];

	private _dataSorting: ITableHeaderEvent<number>;
	public dataSearching: ITableHeaderEvent<string>;
	public isOutSearching: boolean;

	public sizeColumn: string = '';

	constructor(
		private _cdr: ChangeDetectorRef,
	) {
		this._originData = [];
		this._headers = [];
		this._dataSorting = {value: 0, index: 0};
		this.dataSearching = {value: '', index: 0};
		this.sizeColumn = '';
	}

	@HostListener('document:click') click() {
		this.vitualScroll?.checkViewportSize();
	}

	@ContentChildren(HeaderItemDirective)
	public _headerItems: QueryList<HeaderItemDirective>;

	@ContentChildren(ViewItemDirective)
	set viewItems(value: QueryList<ViewItemDirective>) {
		this._viewItems = value;

		this.initHeaders();
		this._cdr.markForCheck();
	}

	@ContentChildren(TablesDirective)
	set lineValues(value: QueryList<TablesDirective>) {
		this._lineValues = value;
	}

	get columns(): ViewItemDirective[] {
		return this._viewItems.toArray();
	}

	get cells(): TablesDirective[] {
		return this._lineValues.toArray();
	}

	get headerItems(): HeaderItemDirective[] {
		return this._headerItems.toArray();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!!changes.data) {
			this._originData = [...changes.data?.currentValue];
		}

		if (!!changes.useVirtualScroll || !!changes.useDragAndDrop) {
			nextTick().then(() => this.initHeaders());
		}
	}

	public ngOnInit(): void {
		nextTick().then(() => this.initHeaders());
	}

	private initHeaders(): void {
		this._headers = [];

		this.tableHeaders.forEach(
			(header: TableHeaderComponent, index: number) => {
				header.indexHeader = index;

				this.initSubscriptionForHeader(header);

				this._headers.push(header);
			},
		);

		this.setSizeColumns(this._headers);
	}

	private initSubscriptionForHeader(header: TableHeaderComponent): void {
		if (this.isServerSide) {
			return;
		}

		header.search
			.pipe(untilDestroyed(this))
			.subscribe((search: ITableHeaderEvent<string>) => {
				this.dataSearching = search;

				this.applyMethodsOnData();

				this._cdr.markForCheck();
			});

		header.search$
			?.pipe(untilDestroyed(this))
			.subscribe((value) => this.isOutSearching = !!value);

		header.sort
			.pipe(untilDestroyed(this))
			.subscribe((sort: ITableHeaderEvent<number>) => {
				this._dataSorting = sort;

				this.dropSortingValueHeader(sort.index);
				this.applyMethodsOnData();
				this._cdr.markForCheck();
			});

		header.closeInputSearch
			.pipe(untilDestroyed(this))
			.subscribe((eventCloseInput: ITableHeaderEvent<null>) => {
				this.dropSearchingValueHeader(eventCloseInput.index);
				this._cdr.markForCheck();
			});
	}

	private applyMethodsOnData(): void {
		if (this._originData?.length === 0) {
			return;
		}

		if (!this.dataSearching.value || !this._dataSorting.value) {
			this.data = [...this._originData]; // чтобы потерял ссылку на массив, но не на объект в массиве
		}

		this.searchByField();
		this.sortByField();
	}

	private dropSortingValueHeader(index: number): void {
		this._headers.forEach((header: TableHeaderComponent, i: number) => {
			if (index === i) {
				return;
			}
			header.setSort(0);
		});
	}

	private dropSearchingValueHeader(index: number): void {
		this._headers.forEach((header: TableHeaderComponent, i: number) => {
			if (index === i) {
				return;
			}

			header.closeSearch();
		});
	}

	private sortByField(): void {
		if (this._dataSorting.value === 0 || !this.data) {
			return;
		}

		let getValue = null;

		if (
			typeof this.getValueByPathFields(
				(this._dataSorting.pathFields as string[]),
				this.data[0],
			) === 'string'
		) {
			getValue = value => {
				const fieldValue = this.getValueByPathFields(
					(this._dataSorting.pathFields as string[]),
					value,
				);

				return !!fieldValue ? fieldValue.toUpperCase() : fieldValue;
			};
		} else {
			getValue = value =>
				this.getValueByPathFields((this._dataSorting.pathFields as string[]), value);
		}

		this.data = this.data
			.sort((first, second) => {
				const one = getValue(first);
				const two = getValue(second);

				if (one === two) {
					return 0;
				} else if (one > two) {
					return -1 * this._dataSorting.value;
				} else {
					return this._dataSorting.value;
				}
			});
	}

	private searchByField(): void {
		if (!this.dataSearching.value) {
			return;
		}

		this.data = this._originData.filter(data => {
			const fieldsValue = this.getValueByPathFields(
				this.dataSearching.pathFields,
				data,
			);

			return fieldsValue instanceof Array
				? fieldsValue.reduce(
					(a, c) => a || this.isHaveCoincidence(c, this.dataSearching.value),
					false,
				)
				: this.isHaveCoincidence(fieldsValue, this.dataSearching.value);
		});
	}

	private isHaveCoincidence(value: any, searchLine: string): boolean {
		if (value === null) {
			return false;
		}

		return typeof value === 'number'
			? value.toString().includes(searchLine)
			: value.toLowerCase().includes(searchLine);
	}

	private getValueByPathFields<T extends Object>(
		pathFields: string[] | string[][],
		value: T,
	): any | any[] {
		const isArrayOfArray = pathFields[0] && (pathFields[0] instanceof Array);

		return isArrayOfArray
			? (pathFields as string[][])
				.map((paths: string[]) => this.getValueByPathFields(paths, value))
			: pathFields.reduce((a, c: string) => !!a ? a[c] : null, value);
	}

	private setSizeColumns(tableHeader: TableHeaderComponent[]): void {
		this.sizeColumn = '';

		tableHeader.forEach(header => {
			let sizeColumn: string;
			if (!!header?.sizeColumn?.sizeColumn) {
				sizeColumn = TABLE_SIZE_COLUMN[header.sizeColumn.sizeColumn];
			} else {
				sizeColumn = DEFAULT_SIZE_COLUMN;
			}

			if (!!header?.sizeColumn?.minSize) {
				sizeColumn = this.replaceAt(
					sizeColumn,
					header.sizeColumn.minSize,
					8,
					sizeColumn.indexOf(','),
				);
			}

			if (!!header?.sizeColumn?.maxSize) {
				sizeColumn = this.replaceAt(
					sizeColumn,
					header.sizeColumn.maxSize,
					sizeColumn.indexOf(',') + 2,
					sizeColumn.indexOf(')'),
				);
			}

			this.sizeColumn += sizeColumn;
		});
	}

	private replaceAt(
		str: string,
		replacement: string,
		startIndex: number,
		endIndex: number,
	): string {
		return (
			str.substr(0, startIndex) +
			replacement +
			str.substr(endIndex, str.length)
		);
	}
}

<table
	#tableRef
	*ngIf="useVirtualScroll && !useDragAndDrop"
	class="table"
>
	<thead class="owl-th g-unselectable"
		   [ngStyle]="{'gridTemplateColumns': sizeColumn}"
	>
	<th
		*ngFor="let item of columns; let i = index"
		class="th"
	>
		<ng-container
			[ngTemplateOutlet]="item.templateRef"
		></ng-container>
	</th>
	</thead>

	<tbody class="g-relative-w100-h100">
	<ng-container *ngIf="!data?.length">
		<div class="g-absolute g-inset-0">
			<ng-content select="[noData]"></ng-content>
		</div>
	</ng-container>


	<cdk-virtual-scroll-viewport
		#vitualScroll
		[itemSize]="itemSize"
	>
		<div
			class="owl-td"
			[ngStyle]="{'gridTemplateColumns': sizeColumn}"
		>
			<tr
				*ngIf="headerItems?.length"
				class="table-line"
			>
				<td
					*ngFor="let item of headerItems; let i = index"
					class="td"
				>
					<ng-container
						*ngTemplateOutlet="item.templateRef;"
					></ng-container>
				</td>
			</tr>
			<tr
				*cdkVirtualFor="let itemData of data; let indexCol = index"
				class="table-line"
			>
				<td
					*ngFor="let item of cells; let i = index"
					class="td"
				>
					<ng-container
						*ngTemplateOutlet="item.templateRef; context: {$implicit: itemData, index: indexCol}"
					></ng-container>
				</td>
			</tr>
		</div>
	</cdk-virtual-scroll-viewport>
	<app-scroll-to-top
		class="g-button-scroll-to-top"
		[cdkVirtualScrollViewport]="vitualScroll"
	></app-scroll-to-top>

	</tbody>
</table>

<table
	#tableRef
	*ngIf="!useVirtualScroll || useDragAndDrop"
	class="table"
>
	<thead class="owl-th g-unselectable"
		   [ngStyle]="{'gridTemplateColumns': sizeColumn}"
	>
	<th
		*ngFor="let item of columns; let i = index"
		class="th"
	>
		<ng-container
			[ngTemplateOutlet]="item.templateRef"
		></ng-container>
	</th>
	</thead>

	<tbody class="g-relative-w100-h100">
	<ng-container *ngIf="!data?.length">
		<div class="g-absolute g-inset-0">
			<ng-content select="[noData]"></ng-content>
		</div>
	</ng-container>

	<cdk-virtual-scroll-viewport
		#virtualScroll
		[itemSize]="itemSize"
	>
		<div
			class="owl-td"
			[ngStyle]="{'gridTemplateColumns': sizeColumn}"
			cdkDropList
			(cdkDropListDropped)="dropListDropped.emit($event)"
		>
			@for (itemData of data; track itemData.id) {
				<tr
					class="table-line"
					cdkDrag
					[cdkDragDisabled]="!useDragAndDrop"
				>
					<td
						*ngFor="let item of cells; let i = index"
						class="td"
					>
						<ng-container
							*ngTemplateOutlet="item.templateRef; context: {$implicit: itemData, index: $index}"
						></ng-container>
					</td>
				</tr>
			}

		</div>
	</cdk-virtual-scroll-viewport>
	<app-scroll-to-top
		class="g-button-scroll-to-top"
		[cdkVirtualScrollViewport]="virtualScroll"
	></app-scroll-to-top>
	</tbody>
</table>

<div class="table-pagination">
	<ng-content select="[pagination]"></ng-content>
</div>

<ng-container *ngIf="!data?.length">
	<app-not-found-data
		*ngIf="this.dataSearching?.value || isOutSearching"
		class="hints"
	></app-not-found-data>
	<app-empty-data
		*ngIf="!this.dataSearching?.value && !isOutSearching"
		class="hints"
	></app-empty-data>
</ng-container>


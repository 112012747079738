import { Injectable } from '@angular/core';
import {from, Observable, of, timer} from 'rxjs';
import {HttpEvent, HttpRequest} from '@angular/common/http';
import {delay} from 'rxjs/operators';

@Injectable()
export default class TaskManager {
	private _tasks: Map<string, Observable<any>>;

	constructor() {
		this._tasks = new Map<string, Observable<any>>;
	}


	public add(taskName: string, task: Observable<any>): void {
		this._tasks.set(taskName, task);

		task.pipe(delay(300)).subscribe({
			complete: () => this._tasks.delete(taskName),
		});
	}

	public isHave(name: string): boolean {
		return this._tasks.has(name);
	}

	public get(taskName: string): Observable<any> {
		return this._tasks.get(taskName);
	}

	public run(
		taskName: string,
		funcTask: () => Observable<HttpEvent<any>>
	): Observable<any> {
		if (this.isHave(taskName)) {
			return this._tasks.get(taskName);
		}

		const task = from(new Promise(res => {
			funcTask().subscribe(data => {
				if (data.type === 0) { return; }
				res(data);
			});
		}));

		this.add(taskName, task);

		return task;
	}
}

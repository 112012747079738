import { NgModule } from '@angular/core';
import { NotFoundDataComponent } from '@shared/ui-components/not-found/not-found-data.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [NotFoundDataComponent],
	exports: [NotFoundDataComponent],
	imports: [MatIconModule],
})
export class NotFoundDataModule {}

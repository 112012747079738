import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, map} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import {ISizeColumn, ITableHeaderEvent} from '@shared/ui-components/table/models/table.types';


@UntilDestroy()
@Component({
	selector: 'app-table-header',
	templateUrl: 'table-header.component.html',
	styleUrls: ['./table-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderComponent implements OnInit {
	@Input() searchByField: string[] | string[][];
	@Input() sortByField: string[];
	@Input() sizeColumn: ISizeColumn = null;
	@Input() search$: Subject<string>;
	@Input() isServerSide: boolean;

	@Output() search: EventEmitter<ITableHeaderEvent<string>>;
	@Output() sort: EventEmitter<ITableHeaderEvent<number>>;
	@Output() closeInputSearch: EventEmitter<ITableHeaderEvent<number>>;

	@ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

	public searchInput$: BehaviorSubject<string>;
	public searchControl: FormControl;
	public isSearching: boolean;
	public sorting: number;
	public indexHeader: number;

	constructor(
		private changeDetector: ChangeDetectorRef,
	) {
		this.searchInput$ = new BehaviorSubject<string>('');

		this.searchControl = new FormControl('');

		this.isSearching = false;
		this.sorting = 0;
		this.indexHeader = null;

		this.search = new EventEmitter<ITableHeaderEvent<string>>();
		this.sort = new EventEmitter<ITableHeaderEvent<number>>();
		this.closeInputSearch = new EventEmitter<ITableHeaderEvent<null>>();
	}

	public ngOnInit(): void {
		this.searchControl.valueChanges
			.pipe(
				debounceTime(300),
				map(value => value.toLowerCase()),
				untilDestroyed(this),
			).subscribe((value) => {
			if (!!this.search$) {
				this.search$.next(value);
			}

			if (!!this.searchByField) {
				this.search.emit({
					value: value,
					pathFields: this.searchByField,
					index: this.indexHeader,
				});
			}
		});

		this.searchControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe(this.searchInput$);
	}

	public setSearch(): void {
		if (this.isSearching && !!this.searchControl.value) {
			this.searchControl.setValue('');
		}

		this.closeInputSearch.emit({index: this.indexHeader});

		this.isSearching = !this.isSearching;

		if (this.isSearching) {
			this.inputElement.nativeElement.focus();
		}

		this.changeDetector.detectChanges();
	}

	public closeSearch(): void {
		this.isSearching = false;

		this.changeDetector.detectChanges();
	}

	public setSort(value: number): void {
		this.sorting = value;

		this.changeDetector.detectChanges();
	}

	public changeSort(): void {
		this.sorting--;

		if (this.sorting === -2) {
			this.sorting = 1;
		}

		if (this.sortByField?.length) {
			this.sort.emit({
				value: this.sorting,
				pathFields: this.sortByField,
				index: this.indexHeader,
			});
		}
	}

	public getTooltipTitle(): string {
		switch (this.sorting) {
			case 0:
				return 'Сортировать по убыванию';
			case 1:
				return 'Сортировать по умолчанию';
			default:
				return 'Сортировать по возрастанию';
		}
	}
}

import {BehaviorSubject, Observable} from 'rxjs';

type TConvert = <T>(
	obs: Observable<T>,
	sub?: BehaviorSubject<T>
) => BehaviorSubject<T>;

export const observableToBehaviorSubject: TConvert = (obs, sub) => {
	if (!sub) {
		sub = new BehaviorSubject(null);
	}

	obs.subscribe(sub);

	return sub;
};

export default {
	observableToBehaviorSubject,
};

import {Directive, HostListener, Input} from '@angular/core';

@Directive({
	selector: '[appStopPropagation]'
})
export class StopPropagationDirective {
	@Input() isStopPropagation = true;

	@HostListener('click', ['$event'])
	@HostListener('focus', ['$event'])
	@HostListener('submit', ['$event'])
	public onClick(event: any): void {
		if (this.isStopPropagation) {
			event.stopPropagation();
		}
	}
}

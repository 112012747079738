import {Component, Input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
	selector: 'app-empty-data',
	templateUrl: './empty-data.component.html',
	styleUrls: ['./empty-data.component.scss'],
	standalone: true,
	imports: [
		MatIconModule
	]
})
export class EmptyDataComponent {
	@Input() title = 'Пусто';
}

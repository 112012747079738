import { IInterval } from '@shared/models/interval';
import moment from 'moment';

function msToTime(duration: number): string {
	const seconds = parseInt('' + ((duration / 1000) % 60));
	const minutes = parseInt('' + ((duration / (1000 * 60)) % 60));
	const hours = parseInt('' + ((duration / (1000 * 60 * 60)) % 24));
	const day = parseInt('' + ((duration / (1000 * 60 * 60 * 24))));

	const h = hours;
	const m = minutes;
	const s = seconds;
	const d = day;

	return d + 'д. ' + h + ' ч. ' + m + 'мин. ' + s + 'сек.';
}

function getTime(): number {
	return new Date().getTime();
}

function getDaysByInterval(
	{ start, end }: IInterval,
): Date[] {
	if (start > end) {
		return [];
	}

	const dateArray: Date[] = [];
	const stopDate = moment(end);
	let currentDate = moment(start);

	while (currentDate <= stopDate) {
		dateArray.push(moment(currentDate).toDate());
		currentDate = moment(currentDate).add(1, 'days');
	}

	return dateArray;
}

export default {
	msToTime,
	getTime,
	getDaysByInterval,
};

import * as _ from 'lodash';
import {nextTick} from './index';
import {IUnitFull} from '../../modules/monitoring/modules/unit/models/unit';
import { from } from 'rxjs';
import { aI } from '@fullcalendar/core/internal-common';

export interface IChangedArrayItem<T, K> {
	newItems?: T[];
	deletedItems?: T[];
	updatedItems?: {
		old: K;
		new: T;
	}[];
}

export default {
	getChangedItems,
	arraySortByLocation,
	unitSortByLocation,
};

export function getChangedItems<T, K>(
	oldArray: T[],
	newArray: T[],
	keys: (keyof T)[]
): IChangedArrayItem<T, K> {
	const getDifference = (arr1, arr2) =>
		arr1?.filter(
			i => !arr2?.find(k => keys.every(key => i[key] === k[key]))
		);

	return {
		newItems: getDifference(newArray, oldArray),
		deletedItems: getDifference(oldArray, newArray),
		updatedItems: oldArray?.reduce((a, c) => {
			const item = newArray?.find(i =>
				keys.every(key => i[key] === c[key])
			);

			if (item && !_.isEqual(item, c)) {
				a.push({old: c, new: item});
			}

			return a;
		}, []),
	};
}

export async function asyncFilter<T>(array: T[], condition: (v: T) => boolean) {
	const filteredArray: T[] = [];

	for (let i = 0; i < array.length; i++) {
		if (!(i % 250)) {
			await nextTick();
		}

		if (condition(array[i])) {
			filteredArray.push(array[i]);
		}
	}

	return filteredArray;
}

export async function asyncForEach<T>(array: T[], func: (v: T) => void) {
	const filteredArray: T[] = [];

	for (let i = 0; i < array.length; i++) {
		if (!(i % 250)) {
			await nextTick();
		}

		func(array[i]);
	}

	return filteredArray;
}

const getNumberArrays = str =>
	str
		.split(new RegExp(/\D/))
		.filter(i => !!i)
		.map(i => Number(i));

export function unitSortByLocation(units: IUnitFull[]) {
	return arraySortByLocation(units, u => u.location.name);
}

export function arraySortByLocation<T extends IUnitFull, P = string>(
	array: T[],
	getLocationName: (item: T) => P
): T[] {
	array.sort((one, two) => {
		const locationName1 = String(getLocationName(one));
		const locationName2 = String(getLocationName(two));

		const numbersOfLocation1 = getNumberArrays(locationName1);
		const numbersOfLocations2 = getNumberArrays(locationName2);

		const minLength = Math.min(
			numbersOfLocation1.length,
			numbersOfLocations2.length
		);

		for (let i = 0; i < minLength; i++) {
			if (numbersOfLocation1[i] === numbersOfLocations2[i]) {
				continue;
			}

			return numbersOfLocation1[i] > numbersOfLocations2[i] ? 1 : -1;
		}

		if (locationName1 !== locationName2) {
			return locationName1 > locationName2 ? 1 : -1;
		}

		const oneIndexIsNumber = digit(one.indexNumber);
		const twoIndexIsNumber = digit(two.indexNumber);

		if (oneIndexIsNumber && twoIndexIsNumber) {
			return Number(one.indexNumber) - Number(two.indexNumber);
		} else if (!oneIndexIsNumber && !twoIndexIsNumber) {
			if (one.unitType?.name !== two.unitType?.name) {
				return one.unitType?.name > two.unitType?.name ? 1 : -1;
			} else {
				// @ts-ignore
				const inventory1 = one.inventoryNumber || one.inventory_number;
				// @ts-ignore
				const inventory2 = two.inventoryNumber || two.inventory_number;

				if (digit(inventory1) && digit(inventory2)) {
					return Number(inventory1) - Number(inventory2);
				} else {
					return inventory1 > inventory2 ? 1 : -1;
				}
			}
		} else if (!oneIndexIsNumber) {
			return -1;
		} else {
			return 1;
		}
	});

	return array;
}

function digit(num: string): boolean {
	return !!num && !isNaN(Number(num));
}

import {nextTick, sleep} from './index';

export default {
	printElement,
};

export async function printElement(divClass: string): Promise<void> {
	const divContent = document.getElementById(divClass);
	const windowPrint = window.open('', '', 'width=800,height=600');

	windowPrint.document.write('<link rel="stylesheet" type="text/css">');
	windowPrint.document.write(divContent.innerHTML);
	windowPrint.document.close();

	windowPrint.focus();

	await nextTick();
	await sleep(100);

	windowPrint.print();
	windowPrint.close();
}


<span class="title-name">
	<ng-content></ng-content>
</span>

<div
	*ngIf="!!sortByField?.length"
	class="toggle-button mr-4"
	[class.switch]='sorting === 1'
	[class.active]='sorting !== 0'
	(click)="changeSort()"
	[matTooltip]="getTooltipTitle()"
>
	<span class="arrow"></span>
</div>

<div
	*ngIf="!!searchByField?.length || !!search$"
	class="search__wrapper"
>
	<div
		class="search"
		[class.active]="isSearching"
	>
		<input
			#inputElement
			[formControl]="searchControl"
			[class.active]="isSearching"
		>

		<button
			mat-icon-button
			(click)="setSearch()"
		>
			<mat-icon
				*ngIf="!isSearching"
				svgIcon="search"
			></mat-icon>
			<mat-icon
				*ngIf="isSearching"
				svgIcon="close"
			></mat-icon>
		</button>
	</div>

</div>

import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({selector: '[lineValue]'})
export class TablesDirective {
	constructor(
		public templateRef: TemplateRef<any>,
	) {
	}
}

@Directive({selector: '[viewItem]'})
export class ViewItemDirective {
	constructor(
		public templateRef: TemplateRef<any>,
	) {
	}
}

@Directive({selector: '[headerItem]'})
export class HeaderItemDirective {
	constructor(
		public templateRef: TemplateRef<any>
	) {
	}
}

@Directive({selector: '[headerTable]'})
export class HeaderTableDirective {
}

@Directive({selector: '[valueTable]'})
export class ValueTableDirective {
	constructor(
		public templateRef: TemplateRef<any>,
		public viewContainer: ViewContainerRef,
	) {
		this.viewContainer.createEmbeddedView(this.templateRef, {});
	}
}

import {NgModule} from '@angular/core';
import {ScrollToTopComponent} from '@shared/ui-components/scroll-to-top/scroll-to-top.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';

@NgModule({
	declarations: [ScrollToTopComponent],
	exports: [ScrollToTopComponent],
	imports: [MatButtonModule, MatIconModule, ScrollingModule, CommonModule],
})
export class ScrollToTopModule {}

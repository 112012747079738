import {NgModule} from '@angular/core';

import {StopPropagationDirective} from '@shared/directives/stop-propagation/stop-propagation.directive';

@NgModule({
	declarations: [StopPropagationDirective],
	exports: [StopPropagationDirective]
})
export class StopPropagationModule {
}

import {animate, style, transition, trigger} from '@angular/animations';

export const untwistingAnimation = trigger('untwistingAnimation', [
	transition('void => *', [
		style({height: 0}),
		animate('.25s ease-in-out', style({height: '*'})),
	]),
	transition('* => void', [
		style({height: '*', opacity: 1}),
		animate('.25s ease-in-out', style({height: 0})),
	]),
]);

export const untwistingWidthAnimation = trigger('untwistingWidthAnimation', [
	transition('void => *', [
		style({width: 0}),
		animate('.3s ease-in-out', style({width: '*'})),
	]),
	transition('* => void', [
		style({width: '*'}),
		animate('.3s ease-in-out', style({width: 0})),
	]),
]);

export const appearanceAnimation = trigger('appearanceAnimation', [
	transition('void => *', [
		style({opacity: 0}),
		animate('.2s', style({opacity: 1})),
	]),
	transition('* => void', [
		style({opacity: 1}),
		animate('.2s', style({opacity: 0})),
	]),
]);

export const getCustomAppearanceAnimation = (time: number) =>
	trigger('appearanceAnimation', [
		transition('void => *', [
			style({opacity: 0}),
			animate(`${time}s`, style({opacity: 1})),
		]),
		transition('* => void', [
			style({opacity: 1}),
			animate(`${time}s`, style({opacity: 0})),
		]),
	]);

export const getCustomUntwistingWidthAnimation = (time: number) =>
	trigger('untwistingWidthAnimation', [
		transition('void => *', [
			style({width: 0}),
			animate(`${time}s ease-in-out`, style({width: '*'})),
		]),
		transition('* => void', [
			style({width: '*'}),
			animate(`${time}s ease-in-out`, style({width: 0})),
		]),
	]);

export const scaleAnimation = trigger('scaleAnimation', [
	transition('void => *', [
		style({scale: 0}),
		animate('0.15s', style({scale: 1})),
	]),
	transition('* => void', [
		style({opacity: 1}),
		animate('0.15s', style({scale: 0})),
	]),
]);

export const scaleAnimationWithWidth = trigger('scaleAnimationWithWidth', [
	transition('void => *', [
		style({scale: 0, width: 0}),
		animate('0.15s', style({scale: 1, width: '*'})),
	]),
	transition('* => void', [
		style({opacity: 1, width: 1}),
		animate('0.15s', style({scale: 0, width: 0})),
	]),
]);

export const getCustomScaleAppearanceAnimation = (time: number = 0.125, scale: number = 0.8) =>
	trigger('scaleAppearanceAnimation', [
		transition('void => *', [
			style({scale: scale, opacity: 0, transform: 'translateY(-15%)'}),
			animate(`${time}s`, style({scale: 1, opacity: 1, transform: 'translateY(0)'})),
		]),
		transition('* => void', [
			style({opacity: 1, scale: 1}),
			animate(`${time}s`, style({opacity: 0, scale: scale, transform: 'translateY(-15%)'})),
		]),
	]);

export const bottomUpAppearanceAnimation = trigger('bottomUpAppearanceAnimation', [
	transition('void => *', [
		style({opacity: 0, transform: 'translateY(100%)'}),
		animate('0.125s', style({opacity: 1, transform: 'translateY(0)'})),
	]),
	transition('* => void', [
		style({opacity: 1, scale: 1}),
		animate('0.125s', style({opacity: 0, transform: 'translateY(100%)'})),
	]),
]);
